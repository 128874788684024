"use client";

import Link from "next/link";
// MUI ICON COMPONENTS
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowRight from "@mui/icons-material/ArrowRight"; // LOCAL CUSTOM COMPONENTS

import { H1, H2 } from "../Typography";
import { FlexBetween, FlexBox } from "../flex-box"; // GLOBAL CUSTOM HOOK

// import useSettings from "hooks/useSettings"; // ===================================================

// ===================================================
export default function SectionHeader({
  title,
  seeMoreLink,
  icon
}) {
  // const {
  //   settings
  // } = useSettings();
  return <FlexBetween mb={3}>
      <FlexBox alignItems="center" gap={1} >
        {/* {icon ?? null} */}
        <H1 lineHeight={1}>{title}</H1>
      </FlexBox>

    

    </FlexBetween>;
}