
import { createContext, useContext, useState, useEffect, useRef } from "react";
import { CartContext } from "contexts/CartContext";


const UserContext = createContext();

const fetchUserData = async (userID) => {
  try {
    const response = await fetch(`/api/customer/account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID: userID })
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to fetch user data');
    }
    return data;
  } catch (error) {
    console.error('Failed to fetch user data:', error);
    throw error;
  }
};

export const UserProvider = ({ children }) => {
  const { userID, setUserID } = useContext(CartContext);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const userDataCache = useRef({}); 

  useEffect(() => {
    if (userID ) {
      if (userDataCache.current[userID]) {
        // Check if we have cached data
        setUser(userDataCache.current[userID]);
        setError(null);
        setLoading(false);
      } else {
        setLoading(true);
        fetchUserData(userID)
          .then(userData => {
            userDataCache.current[userID] = userData; // Cache the fetched data
            setUser(userData);
            setError(null);
          })
          .catch(err => {
            console.error('Error fetching user data:', err);
            setError('Failed to fetch user data');
            setUser(null);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setUser(null);
      setError('No user ID found');
      setLoading(false);
    }
  }, [userID]);

  return (
    <UserContext.Provider value={{ user, setUser, loading, error, userID, setUserID }}>

    {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
