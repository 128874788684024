"use client";

import Link from "next/link";
import { useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button"; // MUI ICON COMPONENTS

import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove"; // GLOBAL CUSTOM HOOK

import useCart from "hooks/useCart"; // GLOBAL CUSTOM COMPONENTS

import LazyImage from "components/LazyImage";
import { H1, H2, H3, H6 } from "components/Typography";
import { FlexBox, FlexRowCenter } from "components/flex-box"; // CUSTOM UTILS LIBRARY FUNCTION

import { currency } from "lib"; // DUMMY DATA
import { useCartActions } from "hooks/useCartActions";
import { TextField } from "@mui/material";
import useHeader from "../../../components/header/hooks/use-header";
import DialogDrawer from "../../../components/header/components/dialog-drawer";

// ================================================================
export default function SmallQuantityCartButton({ product }) {
  const {
    dialogOpen,
    sidenavOpen,
    searchBarOpen,
    toggleDialog,
    toggleSidenav,
    toggleSearchBar,
  } = useHeader();

  const { addToCart } = useCartActions();
  const [quantity, setQuantity] = useState("1");

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleClose = () => setOpenDrawer(false);

  const handleDrawerClose = () => setOpenDrawer(false);
  const handleDrawerToggle = () => setOpenDrawer((state) => !state);

  const handleAddToCart = () => {
    product.name = product.title
    addToCart(product, Number(quantity));
    setQuantity("1");
    toggleSidenav();
  };

  const handleQuantityChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setQuantity(value === "" ? "" : parseInt(value, 10));
  };
  // Clear input on focus if it's the initial value
  const handleFocus = (event) => {
    if (event.target.value === "1") {
      setQuantity("");
    }
  };

  // Restore initial value on blur if empty
  const handleBlur = (event) => {
    if (event.target.value === "") {
      setQuantity("1");
    }
  };

  return (
    <>
      <FlexBox
        justifyContent="flex-start"
        style={{
          borderRadius: "9px",
          width: "90%",
          height: "39px",
        }}
      >
        <input
          // id="filled-basic"
          label="quantity"
          value={quantity}
          onChange={handleQuantityChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            width: "50px",
            padding: "3px",
            textAlign: "center",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "16px",
          }}
        />

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleAddToCart(quantity);
          }}
          sx={{ mb: 2, px: "3.75rem", height: 39 }}
          style={{ marginLeft: "-5px" }}
        >
          Add
        </Button>
      </FlexBox>
      <DialogDrawer
        dialogOpen={dialogOpen}
        sidenavOpen={sidenavOpen}
        toggleDialog={toggleDialog}
        toggleSidenav={toggleSidenav}
      />
    </>
  );
}
