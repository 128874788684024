"use client";

import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import Link from "next/link";

const CookieConsentSnackbar = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consentGiven = sessionStorage.getItem("cookieConsent");
    if (!consentGiven) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    sessionStorage.setItem("cookieConsent", "true");
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <div style={{display: 'flex', border: '1px solid lightgray', position: 'absolute', bottom: '0px', }}>
        <Alert
          onClose={handleClose}
          severity="info"
          sx={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          This site uses functional cookies only. By continuing to use this site,
          you consent to our use of cookies. View our <a href="/info/privacy"><strong>Privacy Policy</strong></a> here for more information.
        </Alert>
      </div>
    </Snackbar>
  );
};

export default CookieConsentSnackbar;
