import { createContext, useReducer, useEffect, useState } from "react";
import CoCart from "../lib/coCartInstance";
import { usePathname } from "next/navigation";
import decryptLogin from "lib/decrypt";

export const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case "SET_CART":
      return { ...state, cart: action.payload };
    case "SET_CART_STATE":
      return { ...state, cart: action.payload };
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, { cart: [] });
  const [userID, setUserID] = useState();

  let cartKey = null;
  let credentials = null;
  const pathname = usePathname();

  useEffect(() => {
    let isMounted = true;
    if (pathname === '/logout') {
    autoLogin()
    }
    fetchAndSetCart();
    return () => {
      isMounted = false;
    };
  }, [pathname]);

  const getCartKey = () => localStorage.getItem("boh_cart_key");
  const getCredentials = async () => await decryptLogin("boh-cart-jwt");

  const fetchAndSetCart = async () => {
    // console.log('cart fetch: userID:', userID)
    const cartKey = getCartKey();
    const credentials = await getCredentials();
    let url = "";

    // First timers don't have a cart to fetch
    if (!credentials && !cartKey) {
      return;
    }

    // Logged in with no key
    else if (credentials && !cartKey) {
      url = "cart";
      CoCart.consumerKey = credentials[0];
      CoCart.consumerSecret = credentials[1];
    }

    // Has a guest cart
    else if (!credentials && cartKey) {
      url = `cart?cart_key=${cartKey}`;
    }

    // Has both, reconcile carts
    else if (credentials && cartKey) {
      url = `cart?cart_key=${cartKey}`;
      CoCart.consumerKey = credentials[0];
      CoCart.consumerSecret = credentials[1];
    }

    if (url !== "") {
      try {
        const response = await CoCart.get(url);

        const cartItems = response.data.items.map((item) => ({
          id: item.id,
          name: item.name,
          price: parseFloat(item.price),
          qty: item.quantity.value,
          imgUrl: item.featured_image,
          slug: item.slug,
          item_key: item.item_key,
        }));
        dispatch({ type: "SET_CART", payload: cartItems });

        // If we just reconciled the cart
        if (credentials !== null && cartKey !== null)
          localStorage.removeItem("boh_cart_key");

        // ALways save and update the woo_cart_key
        if (response.headers && response.headers["cocart-api-cart-key"]) {
          storeCartKeyUsedForCheckout(response.headers["cocart-api-cart-key"]);
          // console.log('stored cart key for checkout')
          if (credentials !== null)
            setTheUserID(response.headers["cocart-api-cart-key"]);
            // await autoLogin();
        }
      } catch (error) {
        console.error("Failed to fetch cart:", error);
      }
    } else console.log("empty url called: creds/key", credentials, cartKey);
  };

  // useEffect(() => {
  //   fetchAndSetCart();
  // }, [pathname]);

  // useEffect(() => {
  //   let isMounted = true;
  //   if (pathname === '/logout') {
  //   autoLogin()
  //   }
  //   fetchAndSetCart();
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [pathname]);


  const autoLogin = async () => {
    try {
      const response = await fetch(`/api/customer/login`);
      const data = await response.json();
      console.log("Autologin Response:", data);
    } catch (error) {
      // console.error("Could not autologin");
    }
  };

  const setCart = (cart) => {
    dispatch({ type: "SET_CART_STATE", payload: cart });
  };

  const setTheUserID = (id) => setUserID(id);

  const storeCartKey = (key) => {
    localStorage.setItem("boh_cart_key", key);
  };

  const storeCartKeyUsedForCheckout = (key) => {
    localStorage.setItem("woo_cart_key", key);
  };

  const getCartKeyUsedForCheckout = async () => {
    await fetchAndSetCart();
    return localStorage.getItem("woo_cart_key");
  };

  const contextValue = {
    cart: state.cart,
    refreshCart: fetchAndSetCart,
    setCart,
    getCartKey,
    storeCartKey,
    getCredentials,
    CoCart,
    getCartKeyUsedForCheckout,
    storeCartKeyUsedForCheckout,
    userID,
    setUserID
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};
