import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/icons-material/ArrowBack.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/icons-material/ArrowForward.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/icons-material/Favorite.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/Button/index.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/Container/index.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/Divider/index.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/Grid/index.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/styles/index.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/TextField/index.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/workspace/public/assets/images/banners/banner-40.png");
import(/* webpackMode: "eager" */ "/workspace/public/assets/images/banners/banner-41.png");
import(/* webpackMode: "eager" */ "/workspace/public/assets/images/banners/banner-42.png");
import(/* webpackMode: "eager" */ "/workspace/public/assets/images/boh-banners/banner.png");
import(/* webpackMode: "eager" */ "/workspace/public/assets/images/boh-banners/banner1.png");
import(/* webpackMode: "eager" */ "/workspace/public/assets/images/hat.png");
import(/* webpackMode: "eager" */ "/workspace/public/assets/images/products/garlic.png");
import(/* webpackMode: "eager" */ "/workspace/public/assets/images/products/kisspng-organic-food-milk-food-gift-baskets-grocery-5abeaffc1e5b25 1.png");
import(/* webpackMode: "eager" */ "/workspace/public/assets/images/products/paper-bag.png");
import(/* webpackMode: "eager" */ "/workspace/src/app/cookieConsent.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-1/card-1/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-1/card-2/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-1/card-3/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-1/card-4/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-1/card-5/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-4/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-5/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-6/long-product-card.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-7/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-9/styles/index.js");
import(/* webpackMode: "eager" */ "/workspace/src/app/home/section-top/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/components/carousel-cards/carousel-card-2/styles.js");
import(/* webpackMode: "eager" */ "/workspace/src/components/carousel/carousel.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/carousel/styles/index.js");
import(/* webpackMode: "eager" */ "/workspace/src/components/countdown/count-down.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/countdown/useCountDown.js");
import(/* webpackMode: "eager" */ "/workspace/src/components/LazyImage.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/nav-link/nav-link-3.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/nav-link/nav-link.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/newsletter/newsletter.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/product-cards/product-card-2/product-card.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/section-header/section-header.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/service-cards/icon-component.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/Typography.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/icons/CreditCardVerified.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/icons/FeedbackThumbsUp.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/icons/Light.jsx");
import(/* webpackMode: "eager" */ "/workspace/src/icons/Telegram.jsx")